<template>
    <div>
      <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
        <div class="container mx-auto">
          <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
            <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('navbar.online-appeal') }}</h1>
          </div>
  
          <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
            </svg>
            <span>{{ $t('table.go-back') }}</span>
          </a>
  
          <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
            <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
              <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
                <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
                  <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
                </a>
              </li>
              <li aria-current="page">
                <div class="flex items-center">
                  <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"></path>
                  </svg>
                  <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('navbar.online-appeal') }}</span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow bg-slate-50/25 dark:bg-gray-777">
    
    <div class="dark:bg-gray-900">
      <div class="container mx-auto pt-5 pb-1 2xl:px-20 xl:px-10 lg:px-10 md:px-10 sm:px-5 px-5">
        <div class="container mx-auto dark:border-lime-600 m-5 lg:pb-12 sm:pb-0 dark:bg-gray-777">
              <div class = "flex mt-2">
                <div class="flex-auto lg:w-1/1 pr-1">
                  <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('onlineAppeal.fullname') }}:</p>
                  <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" v-model="this.onlineAppeal.fullName" maxlength="100"/>
                  <p v-if="!isFullNameValid" class="text-xs text-red-500 mt-1">*{{ $t('onlineAppeal.validation-message-fullName')}}</p>
                </div>
              </div>
            <div class="lg:flex sm:flex-wrap"> 
              <div class="lg:flex-auto sm:flex-wrap lg:w-1/2 md:text-base sm:w-10/12 pr-1">
                <p class="2xl:text-base xl:text-base lg:text-base md:text-xs sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('onlineAppeal.address') }}:</p>
                <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" v-model="this.onlineAppeal.address" maxlength="100">
                <p v-if="!isAddressValid" class="text-xs text-red-500 mt-1">*{{ $t('onlineAppeal.validation-message-address')}}</p>
              </div>  
              <div class="lg:flex-auto sm:flex-wrap justify-normal  lg:w-1/2 md:text-base sm:w-10/12 pr-1">
                <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('onlineAppeal.phone-number') }}:</p>
                <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" v-model="this.onlineAppeal.phoneNumber" placeholder="+998000000000" maxlength="13"/>
                <p v-if="!isPhoneNumberValid" class="text-xs text-red-500 mt-1">*{{ $t('onlineAppeal.validation-message-phone-number')}}</p>
              </div>
             </div>
              <div class="lg:flex-auto sm:flex-wrap justify-normal  lg:w-1/2 md:text-base sm:w-10/12 pr-1">
                <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('onlineAppeal.email') }}:</p>
                <input class="text-xm p-2 w-full dark:bg-gray-900" type="text" id="email" v-model="this.onlineAppeal.email" placeholder="example@mail.ru" maxlength="100"/>
                <p v-if="!isEmailValid" class="text-xs text-red-500 mt-1">*{{ $t('onlineAppeal.validation-message-email')}}</p>
              </div>
             
             <div class = "flex mt-2">
                <div class="flex-auto lg:w-1/1 pr-1">
                  <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('onlineAppeal.note') }}:</p>
                  <textarea  class="text-xm p-2 w-full dark:bg-gray-900" type="text" maxlength="300" v-model="this.onlineAppeal.note"></textarea>
                  <p v-if="!isNoteValid" class="text-xs text-red-500 mt-1">*{{ $t('onlineAppeal.validation-message-note')}}</p>
                </div>
              </div>
              <div class="flex-auto lg:w-1/1 pr-1">
                
                <div class="flex-auto lg:w-1/1 pr-1">
                  <p class="2xl:text-base xl:text-base lg:text-base md:text-base sm:text-xs font-semibold leading-relaxed text-gray-500 dark:text-lime-400 mt-1">{{ $t('onlineAppeal.file') }}:</p>
                 
                  <div>
                      <input
                        class="pdfRu rounded-md w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        type="file"
                        ref="file"
                        @change="uploadFile()"
                        :class="{
                          'border-green-500': this.onlineAppeal.onlineAppealFileId !== null,
                        }"
                        />
                        <p v-if="!isUploadFileValid" class="text-xs text-red-500 mt-1">*{{ $t('onlineAppeal.validation-message-file')}}</p>
                  </div>
                </div>
            
            <div class="lg:float-right sm:justify-center pt-3.5 grid">
              <button class="lg:text-base md:text-base sm:text-xs transition-colors ease-in p-1 rounded-lg font-semibold hover:bg-blue-200 hover:w-full hover:text-blue-500 dark:hover:text-lime-400 dark:text-lime-300 cursor-pointer dark:hover:bg-gray-700 text-gray-500" 
              @click="updateOrAdd()" type="submit">{{ $t('onlineAppeal.send-online-appeal') }}</button>
            </div>
        </div>
      </div>
    </div>
  </div>
    </div>
    </div>
  </template>
  <script>
  import { toast } from "vue3-toastify";

  export default {
    data(){
      return{
        onlineAppeal:{
          address:null,
          fullName:null,
          email:null,
          dateOfSending: new Date().toISOString().substr(0, 10),
          note:null,
          phoneNumber:null,
          onlineAppealFileId: null,
        },
      };
    },

    methods: {
    validateFullName() {
      this.isFullNameValid = this.onlineAppeal.fullName.length > 8;
    },
    validateAddress() {
      this.isAddressValid = this.onlineAppeal.address.length > 3;
    }, 
    validateEmail() {
      this.isEmailValid = this.onlineAppeal.email.length > 8 && this.onlineAppeal.email.includes("@" && ".");
    },
    validatePhoneNumber() {
      this.isPhoneNumberValid = this.onlineAppeal.phoneNumber.length > 5;
    },
    validateNote(){
      this.isNoteValid = this.onlineAppeal.note.length > 3;
    },
    validateUploadFile(){
      this.isUploadFileValid = this.onlineAppeal.onlineAppealFileId !== null;
    },

      async updateOrAdd() {
        this.loading=true;
        this.validateFullName();
        this.validateAddress();
        this.validateEmail();
        this.validatePhoneNumber();
        this.validateNote();
        this.validateUploadFile();
        if(this.isFullNameValid && this.isAddressValid && this.isEmailValid && this.isPhoneNumberValid && this.isNoteValid){
          try {
              const response = await this.axios.post(
                  "/api/Website/OnlineAppeal/SendOnlineAppeal",
                  this.onlineAppeal,
                  { headers: 
                    { 
                      "Content-Type": "application/json", 
                    }, 
                  }
              );
              this.loading = false;
              if (response.status === 200) {
                const successMessage = this.$t('onlineAppeal.online-appeal-success-submit');
                  toast.success((successMessage), {
                    position: "top-center",
                    transition: "flip",
                    newestOnTop: true,
                    toastStyle: {
                      fontSize: "14px",
                    },
                    autoClose: 1000,
                    onClose: () => {
                      this.clearForm();
                      this.$router.push("/onlineAppeal");
                    }
                  });
              }
          } 
          catch (error) {
            this.loading=false;
            const errorMessage = this.$t('onlineAppeal.online-appeal-error-submit');
            toast.error((errorMessage), {
              position: "top-center",
              transition: "flip",
              newestOnTop: true,
              toastStyle: {
                fontSize: "14px",
              },
            });
          }
        }
},
  async uploadFile() {
        const file = this.$refs['file'].files[0];
        const formData = new FormData();
        formData.append("file", file);

        const response = await this.axios.post(
          "api/Website/OnlineAppeal/UploadOnlineAppeal",
          formData
        );
        
        this.onlineAppeal.onlineAppealFileId = JSON.stringify(
            response.data
          );
      },
    },

    clearForm() {
  this.onlineAppeal = {
      address:null,
      fullName:null,
      email:null,
      dateOfSending: new Date().toISOString().substr(0, 10),
      note:null,
      phoneNumber:null,
      onlineAppealFileId: null,
  };
},
    formatedDate(item) {
      const dateTime = new Date(item);
      return dateTime.toLocaleDateString();
    },
    mounted() {
      if (sessionStorage.getItem("ONLINE_APPEAL")) {
          this.onlineAppealFilesData = JSON.parse(sessionStorage.getItem("ONLINE_APPEAL"));
        }
    },
    watch:{
      'onlineAppeal.fullName'(){
        this.validateFullName();
      },
      'onlineAppeal.address'(){
        this.validateAddress();
      },
      'onlineAppeal.phoneNumber'(){
        this.validatePhoneNumber();
      },
      'onlineAppeal.note'(){
        this.validateNote();
      },
      'onlineAppeal.email'(){
        this.validateEmail();
      },
      'onlineAppeal.onlineAppealFileId'(){
        this.validateUploadFile();
      }
    }
  };
  </script>