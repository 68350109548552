<template>
  <div class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900">
    <div class="container mx-auto">
      <div class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center">
        <h1 class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500">{{ $t('navbar.structure') }}</h1>
      </div>

      <a onclick="window.history.back()" class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
        </svg>
        <span>{{ $t('table.go-back') }}</span>
      </a>

      <nav aria-label="Breadcrumb" class="2xl:block xl:block lg:block md:block hidden">
        <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
          <li class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900">
            <a @click="this.$router.push('/')" class="inline-flex items-center font-medium">
              <svg class="w-4 h-4 mr-2" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span class="hidden sm:block">{{ $t('ceo-message.main-screen') }}</span>
            </a>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"></path>
              </svg>
              <span class="ml-1 font-medium md:ml-2 select-none">{{ $t('navbar.structure') }}</span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="container mx-auto border rounded-xl shadow 2xl:py-16 xl:py-12 lg:py-6 py-2 bg-slate-50/25 px-5 2xl:space-y-14 xl:space-y-14 lg:space-y-14 space-y-4 mb-10 dark:bg-gray-900 dark:text-lime-500 dark:border-lime-500">
    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/unknown.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
        <!-- <img src="~@/assets/membershipDiv.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager"> -->
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-business') }}</h3>
        <!-- <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-business-name') }}</h2> -->
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <!-- <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">dilfuza@uzcsd.uz</h3> -->
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (130)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5 basis-0">
        <img src="~@/assets/img/qrs/businessDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>

    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/cooperateDiv.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-customer') }}</h3>
        <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-customer-name') }}</h2>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">fayzi@uzcsd.uz</h3>
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (160)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/img/qrs/customerDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>

    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/financialDiv.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-finance') }}</h3>
        <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-finance-name') }}</h2>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">odil@uzcsd.uz</h3>
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (120)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/img/qrs/financeDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>

    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/unknown.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 md:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-it') }}</h3>
        <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-it-name') }}</h2>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">nurov@uzcsd.uz</h3>
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (111)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5 basis-0">
        <img src="~@/assets/img/qrs/itDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>

    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/pressDiv.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-relation') }}</h3>
        <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-relation-name') }}</h2>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">yadgar@uzcsd.uz</h3>
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (150)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/img/qrs/publicDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>

    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/lawDiv.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-law') }}</h3>
        <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-law-name') }}</h2>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">salieva@uzcsd.uz</h3>
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (122)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/img/qrs/lawDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>

    <div class="flex items-center h-64 w-full space-x-5">
      <div class="2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/worksDiv.webp" alt="" class="w-[204px] rounded-md shadow-md 2xl:block xl:block lg:block md:block sm:block hidden mx-auto" loading="eager">
      </div>
      <div class="flex flex-col 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 basis-5/5">
        <h3 class="font-bold 2xl:text-lg xl:text-lg md:text-lg sm:text-sm xs:text-sm md:w-[600px] sm:w-[400px]">{{ $t('structure.head-department') }}</h3>
        <h2 class="text-blue-500 2xl:text-2xl xl:text-2xl md:text-2xl sm:text-sm xs:text-sm dark:text-white">{{ $t('structure.head-department-name') }}</h2>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
            </svg>
            <span>e-mail:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">info@uzcsd.uz</h3>
        </div>
        <div class="mt-4 space-x-4">
          <div class="flex items-center space-x-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
            </svg>
            <span>{{ $t('structure.telephone') }}:</span>
          </div>
          <h3 class="2xl:text-base xl:text-base lg:text-base text-xs">+998(71) 211-09-09 (150)</h3>
        </div>
      </div>
      <!-- <div class="text-center 2xl:block xl:block lg:block hidden 2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5">
        <img src="~@/assets/img/qrs/affairsDev.svg" alt="" class="w-48 mx-auto bg-white rounded-xl" loading="eager">
        <span>{{ $t('home-page.contacts') }}</span>
      </div> -->
    </div>
    <div class="mb-10 dark:text-lime-500 select-none cursor-pointer">
      <a download
         v-show="this.$i18n.locale == 'ru'" 
         @click="Download(this.$i18n.locale)"
         class="hover:text-blue-500 justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" class="w-6 h-6 2xl:block xl:block lg:block hidden">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        <span class="uppercase 2xl:text-base xl:text-base lg:text-base text-xs text-center">
        {{ $t('headliner.full-file') }}
      </span>
      </a>
      <a download
      v-show="this.$i18n.locale == 'uz'" 
         @click="Download(this.$i18n.locale)"
         class="hover:text-blue-500 justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" class="w-6 h-6 2xl:block xl:block lg:block hidden">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        <span class="uppercase 2xl:text-base xl:text-base lg:text-base text-xs text-center">
        {{ $t('headliner.full-file') }}
      </span>
      </a>
      <a downlaod
        v-show="this.$i18n.locale == 'en'" 
         @click="Download(this.$i18n.locale)"
         class="hover:text-blue-500 justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" class="w-6 h-6 2xl:block xl:block lg:block hidden">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        <span class="uppercase 2xl:text-base xl:text-base lg:text-base text-xs text-center">
        {{ $t('headliner.full-file') }}
      </span>
      </a>
    </div>
  </div>

</template>

<script>
export default {
  methods: {
    openPdf(){
      this.axios.get('/api/Website/GetPdfById');
    },
    async Download(locale) {
      let uzId = 1496;
      let ruId = 1495;
      let enId = 1494;
      let path = null;
      try {
          switch(locale){
            case 'ru':
            path = "/api/Website/GetPdfById" + ruId;
            break;
            case 'uz':
            path = "/api/Website/GetPdfById" + uzId;
            break;
            case 'en':
            path = "/api/Website/GetPdfById" + enId;
            break;
          }
    let response = await this.axios.get(path, {
            responseType: 'blob' // Set responseType to 'blob' to receive binary data
        });

        let blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        let url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        let link = document.createElement('a');
        link.href = url;

        // Simulate a click on the link to trigger the download
        link.click();

        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
      }
      catch (error) {
        console.log(error);
      }
}
}}
</script>