<template>
    <div v-if="loading">
      <LoadingState></LoadingState>
    </div>
    <div>
      <div
        class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
      >
        <div class="container mx-auto">
          <div
            class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
          >
            <h1
              class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
            >
              {{ $t("navbar.comunication-channels") }}
            </h1>
          </div>
  
          <a
            onclick="window.history.back()"
            class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            <span>{{ $t("table.go-back") }}</span>
          </a>
  
          <nav
            aria-label="Breadcrumb"
            class="2xl:block xl:block lg:block md:block hidden"
          >
            <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
              <li
                class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
              >
                <a
                  @click="this.$router.push('/')"
                  class="inline-flex items-center font-medium"
                >
                  <svg
                    class="w-4 h-4 mr-2"
                    fill="white"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                    ></path>
                  </svg>
                  <span class="hidden sm:block">{{
                    $t("ceo-message.main-screen")
                  }}</span>
                </a>
              </li>
              <li aria-current="page">
                <div class="flex items-center">
                  <svg
                    class="w-6 h-6 text-gray-400"
                    fill="white"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="ml-1 font-medium md:ml-2 select-none">{{
                    $t("home-page.comunication-channels")
                  }}</span>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div
        class="mt-10 container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow pb-5 bg-slate-50/25 dark:bg-gray-900"
      >
        <div class="pt-5 container mx-auto my-auto">
          <div class="grid grid-cols-1 px-5">
            <div class="basis-2/5 my-auto text-center">
              <h1
                class="mb-4 text-xl font-extrabold text-gray-900 dark:text-white 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap uppercase"
              >
                <p>
                  <span class="text-blue-500 dark:text-white">{{
                    $t("comunicationChannels.header-text1")
                  }}</span>
                </p>
                <p>
                  <span class="text-blue-500 dark:text-white">{{
                    $t("comunicationChannels.header-text2")
                  }}</span>
                </p>
                <p>
                  <span class="text-blue-500 dark:text-white">{{
                    $t("comunicationChannels.header-text3")
                  }}</span>
                </p>
              </h1>
              <hr
                class="w-full md:w-64 md:mx-auto h-px bg-gray-200 border-0 dark:bg-lime-400"
              />
            </div>
            <div class="w-full mb-3">
              <table class="table-auto 2xl:w-6/12 2xl:mt-10 2xl:mb-5 text-center 2xl:ml-96 p-11 justify-center items-center">
                    <thead class=" from-blue-600 via-sky-600">
                      <tr class="border-blue-500 border-2  dark:text-lime-500 dark:border-lime-500">
                       <th class="bg-blue-500 text-white p-4 dark:bg-gray-900  dark:text-lime-500"  colspan="2" >{{ $t("comunicationChannels.comunication-channels") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="w-1/2 text-gray-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500">{{ $t("comunicationChannels.phone-number") }}</td>
                        <td class="w-1/2 text-gray-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500">+998 71 209 02 63</td>
                       
                      </tr>
                      <tr>
                        <td class="w-1/2 text-gray-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500">{{ $t("comunicationChannels.email") }}</td>
                        <td class="w-1/2 text-blue-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500">anticore@uzcsd.uz</td>
                      </tr>
                      <tr>
                        <td class="w-1/2 text-gray-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500">{{ $t("comunicationChannels.telegram-messenger") }}</td>
                        <td class="w-1/2 text-blue-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500"><a href="https://t.me/UzCSD_anticor">@uzcsd_anticore</a></td>
                      </tr>
                      <tr>
                        <td class="w-1/2 text-gray-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500">{{ $t("comunicationChannels.online-appeal") }}</td>
                        <td class="w-1/2 text-blue-500 border-2 m-5 p-2 dark:text-lime-500 dark:border-lime-500"><a href="https://uzcsd.uz/onlineAppeal">https://uzcsd.uz/onlineAppeal</a></td>
                      </tr>
                    </tbody>
                  </table>
                  <ul class="space-y-1 max-w-md list-none list-inside text-gray-500 dark:text-lime-400 py-4">
                  <li>
                    <div
                      class="flex items-center space-x-2 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap"
                      >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-8 h-8"
                      >
                      <path
                        fill-rule="evenodd"
                        d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                        clip-rule="evenodd"
                      />
                      </svg>
                      <p class="w-72 text-start">
                        <span>{{ $t("comunicationChannels.location") }}:</span
                        >{{ $t("comunicationChannels.location-1") }},
                        {{ $t("comunicationChannels.location-2") }}
                      </p>
                    </div>
                  </li>
                </ul>
            </div>
           
            <div class="w-full h-96 rounded-lg border">
              <iframe
                class="map w-full h-full rounded-lg"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.1171974507563!2d69.32291863357565!3d41.325516173175046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b288cc179b7%3A0xfb60a376780f37cd!2z0JPQnyAi0KbQtdC90YLRgNCw0LvRjNC90YvQuSDQtNC10L_QvtC30LjRgtCw0YDQuNC5INGG0LXQvdC90YvRhSDQsdGD0LzQsNCzIg!5e0!3m2!1sru!2s!4v1668574767243!5m2!1sru!2s"
                style="border: 0"
                loading="eager"
                v-on:load="stopLoad"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LoadingState from "@/components/LoadingState";
  export default {
    components: {
      LoadingState,
    },
    data() {
      return {
        loading: true,
      };
    },
    methods: {
      stopLoad() {
        this.loading = false;
      },
    },
  };
  </script>
  