<template>
  <div v-if="loading">
    <LoadingState></LoadingState>
  </div>
  <div>
    <div
      class="bg-gradient-to-tr from-blue-600 via-sky-600 to-indigo-500 h-64 pt-[56px] w-full flex items-center dark:bg-gradient-to-tr dark:from-gray-900 dark:via-slate-800 dark:to-gray-900"
    >
      <div class="container mx-auto">
        <div
          class="2xl:border-l-4 xl:border-l-4 lg:border-l-4 md:border-l-4 border-white dark:border-lime-600 2xl:text-start xl:text-start md:text-start text-center"
        >
          <h1
            class="font-bold text-4xl text-white mb-2 indent-2 dark:text-lime-500"
          >
            {{ $t("home-page.contacts") }}
          </h1>
        </div>

        <a
          onclick="window.history.back()"
          class="text-white dark:text-lime-500 2xl:hidden xl:hidden lg:hidden md:hidden inline-flex items-center font-medium hover:text-gray-900 dark:hover:text-white cursor-pointer justify-center w-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          <span>{{ $t("table.go-back") }}</span>
        </a>

        <nav
          aria-label="Breadcrumb"
          class="2xl:block xl:block lg:block md:block hidden"
        >
          <ol class="flex space-x-1 md:space-x-3 text-white dark:text-lime-500">
            <li
              class="flex items-center hover:text-gray-900 dark:hover:text-white cursor-pointer hover:color-gray-900"
            >
              <a
                @click="this.$router.push('/')"
                class="inline-flex items-center font-medium"
              >
                <svg
                  class="w-4 h-4 mr-2"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                  ></path>
                </svg>
                <span class="hidden sm:block">{{
                  $t("ceo-message.main-screen")
                }}</span>
              </a>
            </li>
            <li aria-current="page">
              <div class="flex items-center">
                <svg
                  class="w-6 h-6 text-gray-400"
                  fill="white"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="ml-1 font-medium md:ml-2 select-none">{{
                  $t("home-page.contacts")
                }}</span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div
      class="mt-10 container mx-auto border dark:border-lime-600 m-5 rounded-xl shadow pb-5 bg-slate-50/25 dark:bg-gray-900"
    >
      <div class="pt-5 container mx-auto my-auto">
        <div class="grid grid-cols-1 px-5">
          <div class="basis-2/5 my-auto text-center">
            <h1
              class="mb-4 text-xl font-extrabold text-gray-900 dark:text-white 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap uppercase"
            >
              <span class="text-blue-500 dark:text-white">{{
                $t("contact.contact-csd")
              }}</span>
            </h1>
            <hr
              class="w-full md:w-64 md:mx-auto h-px bg-gray-200 border-0 dark:bg-lime-400"
            />
            <ul
              class="space-y-1 max-w-md list-none list-inside text-gray-500 dark:text-lime-400 py-4"
            >
              <li>
                <div
                  class="flex items-center space-x-2 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5H15v-18a.75.75 0 000-1.5H3zM6.75 19.5v-2.25a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75zM6 6.75A.75.75 0 016.75 6h.75a.75.75 0 010 1.5h-.75A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM6 12.75a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 6a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zm-.75 3.75A.75.75 0 0110.5 9h.75a.75.75 0 010 1.5h-.75a.75.75 0 01-.75-.75zM10.5 12a.75.75 0 000 1.5h.75a.75.75 0 000-1.5h-.75zM16.5 6.75v15h5.25a.75.75 0 000-1.5H21v-12a.75.75 0 000-1.5h-4.5zm1.5 4.5a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 2.25a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75v-.008a.75.75 0 00-.75-.75h-.008zM18 17.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p class="w-72 text-start">
                    <span>{{ $t("contact.location") }}:</span
                    >{{ $t("contact.location-1") }},
                    {{ $t("contact.location-2") }}
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="flex items-center space-x-2 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p class="w-72 text-start">
                    <span>{{ $t("structure.telephoneConslariya") }}:</span>
                    +998 (71) 211-09-09 (102)
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="flex items-center space-x-2 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p class="w-72 text-start">
                    <span>{{ $t("others.client-phone") }}:</span>
                    +998 (71)-211-09-09 (161 - 172)
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="flex items-center space-x-2 2xl:whitespace-nowrap xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-8 h-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <p class="w-72 text-start">
                    <span>Email:</span>
                    info@uzcsd.uz
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="w-full h-96 rounded-lg border">
            <iframe
              class="map w-full h-full rounded-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.1171974507563!2d69.32291863357565!3d41.325516173175046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b288cc179b7%3A0xfb60a376780f37cd!2z0JPQnyAi0KbQtdC90YLRgNCw0LvRjNC90YvQuSDQtNC10L_QvtC30LjRgtCw0YDQuNC5INGG0LXQvdC90YvRhSDQsdGD0LzQsNCzIg!5e0!3m2!1sru!2s!4v1668574767243!5m2!1sru!2s"
              style="border: 0"
              loading="eager"
              v-on:load="stopLoad"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingState from "@/components/LoadingState";
export default {
  components: {
    LoadingState,
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    stopLoad() {
      this.loading = false;
    },
  },
};
</script>
